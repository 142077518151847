<template>
    <div id="kitchensink">
        <section>
            <h2>Button</h2>
            <Button
                class="demo-button"
                :busy="busyButton"
                @click="busyButton = !busyButton"
            >
                Busy button
            </Button>
            <Button
                class="demo-button"
                type="primary"
                icon="clock"
            >
                Icon button
            </Button>
            <Button
                class="demo-button"
                type="outline"
                icon="pin"
                :badge="badgeCounter"
                @click="badgeCounter++"
            >
                Button with badge
            </Button>
            <ReactionButton />
            <div class="graphs">
                <ReactionGraph
                    v-for="graph in 5"
                    :key="graph"
                    :reactions="dummyReactions()"
                />
            </div>

            <h3>Button types</h3>
            <Button
                v-for="buttonType in buttonTypes"
                :key="buttonType"
                class="demo-button"
                :type="buttonType"
            >
                type: {{ buttonType }}
            </Button>
        </section>
        <hr/>
        <section>
            <h2>Timepicker</h2>
            <Timepicker
                v-model="time"
                :start-at="'9:45'"
                :minutes-interval="5"
            />
        </section>
        <hr/>
        <section class="errors pbottom-large">
            <h2>SystemMessage</h2>
            <div class="grid flex-wrap">
                <div
                    v-for="(type, idx) in messageTypes"
                    :key="idx"
                    class="col half mbottom-medium"
                >
                    <div class="card padding-medium depth-2">
                        <SystemMessage :type="type" />
                    </div>
                </div>
            </div>
        </section>
        <section class="news pbottom-large">
            <div class="pbottom-medium">
                <div class="divider" ></div>
            </div>

            <div class="grid pbottom-medium">
                <div class="col two-thirds">
                    <NewsTeaser
                        v-for="news in newsArray"
                        :key="'silence-key-error'"
                        :news="news"
                        :is-new="news.isNew"
                        type="big reverse"
                    />
                </div>
                <div class="col third">
                    <NewsTeaser
                        v-for="news in newsArray"
                        :key="'silence-key-error'"
                        :news="news"
                    />
                </div>
            </div>

            <div class="pbottom-large">
                <div class="divider" ></div>
            </div>

            <div class="grid pbottom-large">
                <div class="col">
                    <NewsTeaser
                        v-for="news in newsArray"
                        :key="'silence-key-error'"
                        :news="news"
                        type="big type"
                    />
                </div>
                <div class="col">
                    <NewsTeaser
                        v-for="news in newsArray"
                        :key="'silence-key-error'"
                        :news="news"
                        type="small"
                    />
                </div>
            </div>

            <div class="pbottom-large">
                <div class="divider" ></div>
            </div>

            <div class="grid pbottom-large">
                <div class="col">
                    <NewsTeaser
                        v-for="news in newsArray"
                        :key="'silence-key-error'"
                        :news="news"
                        type="small"
                        :read="true"
                    />
                </div>
                <div class="col">
                    <NewsTeaser
                        v-for="news in newsArray"
                        :key="'silence-key-error'"
                        :news="news"
                        type="small"
                        :new="true"
                    />
                </div>

                <div class="col">
                    <NewsTeaser
                        v-for="news in newsArray"
                        :key="'silence-key-error'"
                        :news="news"
                        type="small"
                        :draft="true"
                    />
                </div>
            </div>
        </section>

        <section class="pbottom-large">
            <v-popover>
                <!-- This will be the popover target (for the events and position) -->
                <app-button
                    class="tooltip-target"
                    icon="chevron-left"
                >
                    Drop
                </app-button>

                <!-- This will be the content of the popover -->
                <template slot="popover">
                    <app-button v-close-popover>
                        ✕
                    </app-button>

                    <p>
                        Hello Dropdown.
                    </p>
                </template>
            </v-popover>
        </section>

        <div class="button-group pbottom-medium">
            <app-button
                v-tooltip="'Tooltip!'"
                type="primary"
                icon="dots"
                @click="openModal('modal')"
            >
                Normal Modal
            </app-button>

            <app-button
                v-tooltip="'Modal with different position!'"
                type="secondary"
                icon="tool"
                @click="openModal('bottom-modal')"
            >
                Bottom Modal
            </app-button>

            <app-button
                v-tooltip="'Tooltip!'"
                type="warning"
                icon="x"
                @click="openModal('unavoidable')"
            >
                Unavoidable Modal
            </app-button>

            <app-button
                v-tooltip.bottom="'message-square!'"
                type="link"
                icon="message-square"
                @click="openModal('fs')"
            >
                FS Modal
            </app-button>

            <app-button
                v-tooltip.right="'message-square!'"
                type="link primary"
                icon="message-square"
                @click="openModal('drawer')"
            >
                Side Drawer
            </app-button>
        </div>

        <Modal>This is a Modal!!!</Modal>
        <Modal
            name="bottom-modal"
            position="bottom"
        >
            Possible position attribute for modal: default, top, bottom
        </Modal>

        <Modal
            name="unavoidable"
            unavoidable
            warning
            title="Unavoidable"
            position="top"
        >
            Test
        </Modal>

        <Modal
            name="fs"
            fullscreen
        >
            <div class="container focussed padded">
                <h1>What up.</h1>
                <p>
                    Bacon ipsum dolor amet pork belly shoulder kevin meatball burgdoggen
                    swine jerky short loin buffalo porchetta kielbasa. Short loin biltong
                    ham hock doner meatloaf porchetta spare ribs meatball shoulder pig.
                    Jowl turkey tongue meatball. Filet mignon tail andouille shank alcatra
                    chicken, pork chop jerky buffalo sausage ham doner beef jowl.
                </p>
                <p>
                    Pork loin filet mignon shoulder, ribeye tenderloin fatback ham.
                    Tenderloin chuck shankle, filet mignon cow pig pancetta venison
                    pastrami leberkas. Ball tip flank strip steak, tongue jowl buffalo
                    cupim pork belly shoulder leberkas andouille. Doner rump corned beef
                    pork loin beef meatball cow picanha pork belly shank tail jowl. Corned
                    beef jerky turkey chicken. Pastrami jowl jerky pancetta. Corned beef
                    drumstick shoulder turkey rump venison.
                </p>
                <p>
                    Jerky flank fatback, hamburger bresaola brisket beef ribs burgdoggen.
                    Leberkas bacon andouille, cow pastrami tongue flank ground round jowl
                    pork pork chop rump corned beef beef sausage. Pancetta chicken cupim
                    kevin boudin tenderloin. Prosciutto ground round turkey, cupim filet
                    mignon cow pork flank spare ribs porchetta rump picanha leberkas.
                    Bacon tail fatback chuck. Bacon salami fatback drumstick leberkas
                    alcatra pastrami boudin.
                </p>
            </div>
        </Modal>
        <Drawer
            name="drawer"
            size="medium"
        >
            <h1>Side Drawer</h1>
        </Drawer>
    </div>
</template>

<script>
import Drawer from "@web/components/modals/Drawer.vue";
import Modal from "@web/components/modals/Modal.vue";
import NewsTeaser from "@web/components/news/NewsTeaser.vue";
import Button from "@web/components/Button.vue";
import ReactionButton from "@web/components/reaction/ReactionButton.vue";
import ReactionGraph from "@web/components/reaction/ReactionGraph.vue";
import Timepicker from "@web/views/events/Timepicker";

export default {
    components: {
        Timepicker,
        Button,
        Modal,
        Drawer,
        NewsTeaser,
        ReactionButton,
        ReactionGraph
    },
    data() {
        return {
            busyButton: false,
            badgeCounter: 13,
            buttonTypes: [
                "primary",
                "secondary",
                "warning",
                "light",
                "link",
                "link primary",
                "link secondary",
                "link warning",
                // "link text-white",
                "outline",
                "outline primary",
                "outline secondary",
                "outline warning",
            ],
            time: "19:00",
            messageTypes: [
                "unknown",
                "drafts",
                "invites",
                "people",
                "profile",
                "news",
                "liftoff",
                "yay",
                "results",
                "theming",
                "maintenance",
                "success",
                "timeout",
                "403",
                "404",
            ],
            newsArray: [
                {
                    author: "Robert Roy Britt",
                    title: "Coffee, Even a Lot, Linked to Longer Life",
                    subtitle: "Scientists wake up and smell the apparent health benefits",
                    url:
                            "https://wolfstreet.com/2019/08/30/china-imposes-new-capital-controls-targets-foreign-real-estate-purchases-as-yuan-falls-to-11-year-low/",
                    urlToImage: "https://source.unsplash.com/random/800x600",
                    publishedAt: "2019-08-30T05:54:42Z",
                    readAt: "",
                    isNew: true,
                    status: "PUBLISHED"
                },
                {
                    author: "Wolf Blitzer",
                    title: "We Need Farmers, Bum-Da-Bum Bum-Bum-Da-Bum!",
                    subtitle:
                            "Tesla Inc has always shown disdain for marketing, with CEO Elon Musk boasting his company does not advertise, instead using the money it would have spent to develop products.",
                    url:
                            "https://www.reuters.com/article/us-tesla-china-focus/with-racing-and-music-events-tesla-gets-over-marketing-allergy-in-china-idUSKCN1VK0CN",
                    urlToImage: "https://source.unsplash.com/random/700x500",
                    publishedAt: "2019-08-30T05:02:00Z",
                    readAt: "",
                    status: "PUBLISHED"
                },
                {
                    author: "Wolf Blitzer",
                    title: "We Need Farmers, Bum-Da-Bum Bum-Bum-Da-Bum!",
                    subtitle:
                            "Tesla Inc has always shown disdain for marketing, with CEO Elon Musk boasting his company does not advertise, instead using the money it would have spent to develop products.",
                    url:
                            "https://www.reuters.com/article/us-tesla-china-focus/with-racing-and-music-events-tesla-gets-over-marketing-allergy-in-china-idUSKCN1VK0CN",
                    urlToImage: "",
                    publishedAt: "2019-08-30T05:02:00Z",
                    readAt: "2019-08-30T05:02:00Z",
                    status: "PUBLISHED",
                    onReadingList: "true"
                }
            ],
        };
    },
    computed: {
    },
    methods: {
        openModal(name) {
            this.$modal.show(name);
        },
        randomNumber() {
            return Math.floor(Math.random() * 100 * Math.round(Math.random()));
        },
        dummyReactions() {
            return {
                dislike: this.randomNumber(),
                clap: this.randomNumber(),
                love: this.randomNumber(),
                like: this.randomNumber(),
                smile: this.randomNumber()
            };
        }

    },
};
</script>

<style lang="scss" scoped>
    .demo-button {
        margin: 10px;
    }

    .graphs {
        display: flex;
    }
</style>
